<template>
  <div class="noSel" style="height:100vh">
    <div class="flex" style="width:1200px;margin:0 auto;padding-top:50px;">
      <div class="left">
        <div>
          <div class="left_header">常见问题</div>
          <div v-for="(item,i) in problemTypeList" :key="i">
            <div
              class="problemBox"
              :class="{on:problemBoxChosei == i}"
              @click="choseProblemBox(i,item.id)"
            >{{item.text}}</div>
          </div>
        </div>
      </div>
      <div class="right" v-loading="loading">
        <div v-show="this.problemList.length == 0">暂无问题！</div>
        <div v-for="(item,i) in problemList" :key="i" style="margin-bottom:30px;">
          <div class="problemText flex a_c">
            <img src="https://static.5t1800.com/s2/2021/06/23/60d29d68520b9.png" alt />
            <div @click="answerQues(i)">{{item.title}}</div>
          </div>
          <div class="problemText answer flex a_c" v-show="problemAnsi == i">
            <img src="https://static.5t1800.com/s2/2021/06/23/60d2a1061a3c9.png" alt />
            <div style="color:#666666" @click="answerQues(item.cate_id)">{{item.content}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api/api";
export default {
  data() {
    return {
      loading: false,
      problemBoxChosei: 0,
      problemBoxChosei2: 0,
      problemAnsi: 999,
      problemTypeList: [
        {
          id: 100,
          text: "会员帮助",
          isShow: 0,
          img_path:
            "https://static.5t1800.com/s3/2020/07/08/5f051ea314447.jpg",
          text_list: [
            {
              cate_id: 100,
              title: "问题3"
            },
            {
              cate_id: 100,
              title: "问题4"
            }
          ]
        },
        {
          id: 101,
          text: "购物指南",
          isShow: 0,
          img_path:
            "https://static.5t1800.com/s3/2020/07/08/5f051aaedde29.jpg",
          text_list: [
            {
              cate_id: 101,
              title: "问题1"
            },
            {
              cate_id: 101,
              title: "问题2"
            }
          ]
        }
      ],
      problemList: [
        // {
        //   title: "问题1",
        //   content: "答案1"
        // },
        // {
        //   title: "问题2",
        //   content: "https://static.5t1800.com/s3/2020/07/08/5f051ea2d50bf.jpg"
        // }
      ]
      //   problemDetailedList:problemTypeList[problemBoxChose].text_list
    };
  },
  created() {
    this.fixType();
    this.getFAQ_cate_list();
  },
  components: {},
  methods: {
    //查看问题答案
    answerQues(i) {
      console.log(i);
      this.problemAnsi = i;
    },
    //选择问题分类
    choseProblemBox(i, cate_id) {
      this.problemBoxChosei = i;
      this.problemAnsi = 999;
      console.log(cate_id);
      this.getFAQ_list(cate_id);
    },
    //固定type栏状态
    fixType() {
      this.$store.commit("saveTypeIndex", 9);
    },
    //获取问题分类列表
    getFAQ_cate_list() {
      api
        .$FAQ_cate_list()
        .then(res => {
          console.log(res.data);
          this.problemTypeList = res.data.data;
          this.getFAQ_list(res.data.data[0].id);
        })
        .catch(err => {
          console.log(err);
        });
    },
    //获取问题列表
    getFAQ_list(value) {
      this.loading = true;
      var params = {
        cate_id: value
      };
      api
        .$FAQ_list(params)
        .then(res => {
          console.log(res.data);
          this.problemList = res.data.data;
          this.problemList = res.data.data;
          this.loading = false;
        })
        .catch(err => {
          console.log(err);
          this.loading = false;
        });
    }
  }
};
</script>

<style scoped>
.left {
  width: 120px;
  text-align: center;
}
.left_header {
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  margin-bottom: 20px;
}
.problemBox {
  height: 50px;
  line-height: 50px;
  box-sizing: border-box;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999999;
  cursor: pointer;
  margin-bottom: 20px;
}
.left .on {
  color: #333333;
  border-bottom: 2px solid #333333;
}
.right {
  flex: 1;
  padding: 0 20px;
  min-height: 100px;
}
.problemText img {
  width: 28px;
  height: 28px;
  margin-right: 6px;
}
.problemText {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  cursor: pointer;
  margin-bottom: 10px;
}
.problemText div:hover {
  text-decoration: underline;
}
.answer div:hover {
  cursor: auto;
  text-decoration: none;
}
</style>

